// Alert.js

import React, { useState, useEffect } from 'react';
import { Alert as BootstrapAlert, Container, Col, Row } from 'react-bootstrap';

const Alert = ({ notifications, setNotifications }) => {
    const handleClose = (id) => {
        setNotifications((prevStack) => prevStack.filter((item) => item.id !== id));
    };
    return (
        <Container className="position-fixed bottom-0 end-0 p-3">
            <Row className="justify-content-end">
                <Col xs={12} md={6}>
                    {notifications.map((notification) => (
                        <BootstrapAlert
                            key={notification.id}
                            show={true}
                            variant={notification.type}
                            onClose={() => handleClose(notification.id)}
                            dismissible
                        >
                            {notification.message}
                        </BootstrapAlert>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

export default Alert;
