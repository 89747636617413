import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {getAllVoices} from "../http/voiceAPI";
import {VOICE_GENDERS, VOICE_LANGUAGES} from "../utils/consts";

const Entities = ({setLoader, handleNotification}) => {
    const navigate = useNavigate();
    const [entities, setEntities] = useState([]);

    useEffect(() => {
        getEntitiesWorker();
    }, []);

    const getEntitiesWorker = () => {
        setLoader({status: true, message: 'Получение данных'});
        getAllVoices()
            .then(setEntities)
            .catch(err => handleNotification(err, 'danger'))
            .finally(() => setLoader(null));
    }

    return (
        <Container className={'mt-2'}>
            <h1></h1>
            <Button
                className={'mb-2'}
                onClick={() => navigate(`/voice/create`)}
            >Создать</Button>
            <Table striped bordered>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Имя</th>
                    <th>Пол</th>
                    <th>Язык</th>
                    <th>Премиум</th>
                    <th>Активный</th>
                    <th>Дата изменения</th>
                    <th>Дата создания</th>
                </tr>
                </thead>
                <tbody>

                {
                    entities.map((el, index) =>
                        <tr
                            key={el.id}
                            onClick={() => navigate('/voice/' + el.id)}
                            style={{cursor: 'pointer'}}
                        >
                            <td>{index + 1}</td>
                            <td>{el.title}</td>
                            <td>{VOICE_GENDERS[el.gender]}</td>
                            <td>{VOICE_LANGUAGES[el.language]}</td>
                            <td>{el.isPremium ? 'Да' : 'Нет'}</td>
                            <td>{el.isActive ? 'Да' : 'Нет'}</td>
                            <td>{new Date(el.updatedAt).toLocaleString()}</td>
                            <td>{new Date(el.createdAt).toLocaleString()}</td>
                        </tr>
                    )
                }

                {/*{*/}
                {/*    filterEntities.map((el, index) =>*/}
                {/*        <tr*/}
                {/*            key={`${el.name}-${el.id}`}*/}
                {/*            onClick={()=>navigate(`/${type}/${el.id}`)}*/}
                {/*            style={{cursor: "pointer"}}*/}
                {/*        >*/}
                {/*            <td>{index + 1}</td>*/}
                {/*            <td>{el.name}</td>*/}
                {/*            <td>{new Date(el.updatedAt).toLocaleString()}</td>*/}
                {/*            <td>{new Date(el.createdAt).toLocaleString()}</td>*/}
                {/*            {type === 'categories' && <td>*/}
                {/*                <Form.Control*/}
                {/*                    type={'number'}*/}
                {/*                    placeholder={el.position+1}*/}
                {/*                    onClick={e=>e.stopPropagation()}*/}
                {/*                    onKeyDown={e=>updateCategoryPositionHandler(el.id, e)}*/}
                {/*                />*/}
                {/*            </td>}*/}
                {/*        </tr>*/}
                {/*    )*/}
                {/*}*/}
                </tbody>
            </Table>
        </Container>
    );
};

export default Entities;