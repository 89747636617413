import {$authHost, $host} from "./index";

export const getAllVoices = async () =>{
    const {data} = await $authHost.get('api/voice/all');
    return data;
}

export const getOneVoice = async (id) => {
    const {data} = await $authHost.get(`api/voice/${id}`);
    return data;
}

export const createVoice = async (body) => {
    const {data} = await $authHost.post('api/voice', body);
    return data;
}

export const updateVoice = async (id, body) => {
    const {data} = await $authHost.patch(`api/voice/${id}`, body);
    return data;
}

export const deleteVoice = async (id) => {
    const {data} = await $authHost.delete(`api/voice/${id}`);
    return data;
}