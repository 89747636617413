import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { IMAGES_EXT, SOUNDS_EXT, VOICE_GENDERS, VOICE_LANGUAGES } from "../utils/consts";
import { createVoice, deleteVoice, getOneVoice, updateVoice } from "../http/voiceAPI";

const EditVoice = ({ setLoader, handleNotification }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [currentVoice, setCurrentVoice] = useState({
        title: '',
        gender: Object.keys(VOICE_GENDERS)[0],
        language: Object.keys(VOICE_LANGUAGES)[0],
        image: null,
        voice: null,
        isPremium: false,
        isActive: false
    });
    const [dataImage, setDataImage] = useState();
    const [dataVoice, setDataVoice] = useState();
    const [audioKey, setAudioKey] = useState(0);

    useEffect(() => {
        if (id !== 'create') {
            setLoader({ status: true, message: 'Получение голоса' });
            getOneVoice(id)
                .then(res => setCurrentVoice({ ...res }))
                .catch(err => {
                    handleNotification(err, 'danger');
                    navigate('/');
                })
                .finally(() => setLoader(null));
        }
    }, []);

    const saveSound = () => {
        if (!currentVoice.title)
            return handleNotification('Введите заголовок', 'danger');
        if (!currentVoice.gender)
            return handleNotification('Выберите пол', 'danger');
        if (!currentVoice.language)
            return handleNotification('Выберите язык', 'danger');

        const formData = new FormData();

        if (id === 'create') {
            if (!currentVoice.voice)
                return handleNotification('Отсутствует голос', 'danger');
            if (!currentVoice.image)
                return handleNotification('Отсутствует изображение', 'danger');
        }
        if (currentVoice.voice)
            formData.append('voice', currentVoice.voice);
        if (currentVoice.image)
            formData.append('image', currentVoice.image);

        formData.append('title', currentVoice.title);
        formData.append('gender', currentVoice.gender);
        formData.append('language', currentVoice.language);
        formData.append('isPremium', currentVoice.isPremium);
        formData.append('isActive', currentVoice.isActive);

        setLoader({ status: true, message: 'Сохранение голоса' });
        if (id === 'create')
            createVoice(formData)
                .then(() => handleNotification('Голос сохранен', 'success'))
                .catch(err => handleNotification(err, 'danger'))
                .finally(() => {
                    setLoader(null);
                    navigate('/')
                });
        else
            updateVoice(id, formData)
                .then(() => handleNotification('Голос сохранен', 'success'))
                .catch(err => handleNotification(err, 'danger'))
                .finally(() => {
                    setLoader(null);
                    navigate('/')
                });
    }

    const deleteCurrentVoice = () => {
        setLoader({ status: true, message: 'Удаление голоса' });
        deleteVoice(id)
            .then(() => handleNotification('Голос удален', 'success'))
            .catch(err => handleNotification(err, 'danger'))
            .finally(() => {
                setLoader(null);
                navigate('/');
            });
    }

    const onChangeFile = (event, fileType) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (fileType === 'image') {
                    setDataImage(reader.result);
                    setCurrentVoice({ ...currentVoice, image: file });
                }
                if (fileType === 'voice') {
                    setDataVoice(reader.result);
                    setCurrentVoice({ ...currentVoice, voice: file });
                }
            };

            reader.readAsDataURL(file);
        }
    }

    useEffect(() => {
        if (dataVoice) {
            setAudioKey(prevKey => prevKey + 1); // Update key only when dataVoice changes
        }
    }, [dataVoice]);

    return (
        <Container className={'mt-2'}>
            <h1>{id !== 'create' ? 'Изменение' : 'Создание'} голоса</h1>

            <Form>
                <Form.Group className={'mt-2'}>
                    <Form.Label>Заголовок</Form.Label>
                    <Form.Control
                        value={currentVoice.title}
                        onChange={e => setCurrentVoice({ ...currentVoice, title: e.target.value })}
                        placeholder={'Имя'}
                    />
                </Form.Group>

                <Form.Group className={'mt-2'}>
                    <Form.Label>Пол</Form.Label>
                    <Form.Select
                        value={currentVoice.gender}
                        onChange={e => setCurrentVoice({ ...currentVoice, gender: e.target.value })}
                    >
                        {Object.keys(VOICE_GENDERS).map(el =>
                            <option value={el} key={'gender_' + el}>{VOICE_GENDERS[el]}</option>
                        )}
                    </Form.Select>
                </Form.Group>
                <Form.Group className={'mt-2'}>
                    <Form.Label>Язык</Form.Label>
                    <Form.Select
                        value={currentVoice.language}
                        onChange={e => setCurrentVoice({ ...currentVoice, language: e.target.value })}
                    >
                        {Object.keys(VOICE_LANGUAGES).map(el =>
                            <option value={el} key={'language_' + el}>{VOICE_LANGUAGES[el]}</option>
                        )}
                    </Form.Select>
                </Form.Group>

                <Form.Group className={'mt-2'}>
                    <Form.Label>Премиум</Form.Label>
                    <Form.Check
                        checked={currentVoice.isPremium}
                        onChange={e => setCurrentVoice({ ...currentVoice, isPremium: e.target.checked })}
                    />
                </Form.Group>

                <Form.Group className={'mt-2'}>
                    <Form.Label>Активный</Form.Label>
                    <Form.Check
                        checked={currentVoice.isActive}
                        onChange={e => setCurrentVoice({ ...currentVoice, isActive: e.target.checked })}
                    />
                </Form.Group>

                <Form.Group className="mb-2">
                    <Form.Label>Изображение</Form.Label>
                    <Form.Control type={'file'}
                                  accept={IMAGES_EXT.join(', ')}
                                  onChange={(e) => onChangeFile(e, 'image')}
                    />
                </Form.Group>
                {(dataImage || currentVoice.image) && (
                    <img className={'mb-2'} src={dataImage || currentVoice.image} alt="Image"
                         style={{ maxWidth: '100%', maxHeight: '200px' }} />
                )}

                <Form.Group className="mb-2">
                    <Form.Label>Звук</Form.Label>
                    <Form.Control type={'file'}
                                  accept={SOUNDS_EXT.join(', ')}
                                  onChange={(e) => onChangeFile(e, 'voice')}
                    />
                </Form.Group>
                {(dataVoice || currentVoice.voice) && (
                    <audio key={audioKey} className={'mb-2'} controls>
                        <source src={dataVoice || currentVoice.voice} type={'audio/wav'}/>
                    </audio>
                )}
            </Form>

            <Button className={'mt-2'} onClick={saveSound}>Сохранить</Button><br/>
            {id !== 'create' && <Button className={'mt-2'} onClick={deleteCurrentVoice}>Удалить</Button>}
        </Container>
    );
};

export default EditVoice;
