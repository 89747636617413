export const IMAGES_EXT = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.bmp',
    '.tiff',
    '.svg',
    '.webp',
    '.heic',
    '.ico'
];
export const SOUNDS_EXT = [
    '.mp3',
    '.aac',
    '.ogg',
    '.flac',
    '.wav',
    '.m4a',
    '.wma',
    '.alac',
    '.aiff',
    '.opus'
];

export const VOICE_LANGUAGES = {
    'en': 'Английский',
    'es': 'Испанский',
    'fr': 'Французский',
    'de': 'Немецкий',
    'it': 'Итальянский',
    'pt': 'Португальский',
    'pl': 'Польский',
    'tr': 'Турецкий',
    'ru': 'Русский',
    'nl': 'Нидерландский',
    'cs': 'Чешский',
    'ar': 'Арабский',
    'zh-cn': 'Китайский (S)',
    'hu': 'Hungarian',
    'ko': 'Корейский',
    'hi': 'Хинди'
};
export const VOICE_GENDERS = {
    'Man': 'Мужской', 'Woman': 'Женский'
};